import { combineReducers } from 'redux';
import { jokeReducer } from './joke';
import { searchJokesReducer } from './search';

export const rootReducer = combineReducers({
    jokeState: jokeReducer,
    search: searchJokesReducer
});

export type ReduxState = ReturnType<typeof rootReducer>
