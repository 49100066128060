import React from 'react';
import './list.scss';
import { BackButton } from '../../components/input/button';
import { useHistory } from 'react-router-dom';

export const ListHeader: React.FC<{}> = (props) => {

    const history = useHistory();

    const back = () => {
        if(document.referrer.indexOf(window.location.host) !== -1) { 
          history.goBack();
        } else { 
          history.push('/')
        }
        
    }

    return (<div className="list-header">
      <div className="list-header--back"><BackButton onClick={back}>back</BackButton></div>
      <h4>Joke</h4>
    </div>)
}