import React, { useEffect } from 'react';
import { JokeCard, JokeListCard } from '../../components/joke';
import { loadJoke } from '../../actions/joke';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import ClockLoader from "react-spinners/ClipLoader";
import './joke.scss';
import { Joke404 } from '../../components/error';

const Loader: React.FC<{}> = () => {
    return <div className="joke-card-list-loader">
      <ClockLoader
          size={20}
          color={"rgba(255,255,255,0.8)"}
          loading={true}
      />
    </div>
}

export const Joke: React.FC<{id: string}> = (props) => {

    const dispatch = useDispatch();
    const joke = useSelector((state: ReduxState) => state.jokeState);
    
    useEffect(() => {
      dispatch(loadJoke(props.id))
    }, [dispatch]);

    if(joke.error && (joke.error === 'not_found' || joke.error === 'incorrectly_formatted_id')) {
      return <Joke404 />
    }

    // if no joke, show loader
    if(!joke.joke) {
      return <Loader />
    }

    return (<>
      <JokeCard {...joke.joke} />
    </>)
}