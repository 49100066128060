import React, { useEffect } from 'react';
import { JokeListCard } from '../../components/joke';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import ClockLoader from "react-spinners/ClipLoader";
import { searchJokes, resetJokesSearch } from '../../actions/search';
import InfiniteScroll from 'react-infinite-scroller';

const Loader: React.FC<{}> = () => {
  return <div className="joke-card-list-loader">
    <ClockLoader
        size={20}
        color={"rgba(255,255,255,0.8)"}
        loading={true}
    />
  </div>
}

export const SearchJokes: React.FC<{query?:string, page?:number}> = (props) => {

    const dispatch = useDispatch();
    const state = useSelector((state: ReduxState) => state.search);

    useEffect(() => {
      if(props.query) {
        dispatch(resetJokesSearch());
        dispatch(searchJokes(props.query, props.page))
      }
    }, [dispatch, props.query]);

    const loadMore = () => {
      if(props.query) {
        dispatch(searchJokes(props.query))
      }
    }

    // show loader of first load
    if(state.results.length < 1 && state.loading) return <Loader />

    // no results
    if(state.results.length < 1) return <div className="search--no-results">No Results</div>

    return (<>
      <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={state.results.length < 1}
          loader={<div><Loader /></div>}>
          {state.results.map((joke, key) => <JokeListCard {...joke} key={key} />)}
      </InfiniteScroll> 
    </>)

}