import { 
    SEARCH_JOKES,
    SEARCH_JOKES_SUCCESS,
    SEARCH_JOKES_FAILURE,
    RESET_SEARCH_STATE,
    SearchAction,
    ISearchState
} from '../interfaces/joke';

const initialState: ISearchState = { 
    loading: true, 
    results: [], 
    page: 1,
}

export const searchJokesReducer = (state = initialState, action: SearchAction) => {  
    switch (action.type) {  
        case RESET_SEARCH_STATE: { 
            return {...state, results: [], loading: false, page: state.page+1};        
        } 
        case SEARCH_JOKES: {           
            return {...state, loading: true, error:''};       
        } 
        case SEARCH_JOKES_SUCCESS: { 
            // remove any duplicates
            let ids = new Set(state.results.map(joke => joke.id));
            let newState = action.results.filter(joke => !ids.has(joke.id));
            return {...state, results: [...state.results, ...newState], loading: false, page: state.page+1};        
        }       
        case SEARCH_JOKES_FAILURE: {           
            return {...state, loading: false,  error: action.error};       
        }     
        default: {           
            return state;       
        }   
    }
}