import React, { useEffect } from 'react';
import './header.scss';
import logo from '../../assets/images/logo-icon.png';
import { useHistory } from 'react-router-dom';
import { Search } from '../../components/input/search';

export const Header: React.FC<{}> = (props) => {

    const history = useHistory();
    const goHome = () => {
        const link = '/';
        history.push(link);
    }

    return (<header className="primary-header">
        <img onClick={goHome} className="primary-header--logo" alt="Laughfloor Logo" src={logo} />
        <div className="primary-header--search">
            <Search />
        </div>
    </header>)
}