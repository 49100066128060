import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import axios from 'axios';
import { ReduxState } from '../reducers';
import { loadAllJokesSuccessAction } from './joke';

import { 
    SEARCH_JOKES,
    SEARCH_JOKES_SUCCESS,
    SEARCH_JOKES_FAILURE,
    RESET_SEARCH_STATE,
    SearchAction,
    ISearchState,
    IJoke,
} from '../interfaces/joke';

// RESET SEARCH STATE

export const resetJokesSearch = (results = []): SearchAction => {
    return { type: RESET_SEARCH_STATE, results }
}

// SEARCH JOKES

export const searchJokesAction = (): SearchAction => {
    return { type: SEARCH_JOKES }
}

export const searchJokesSuccessAction = (results: Array<IJoke>): SearchAction => {
    return { type: SEARCH_JOKES_SUCCESS, results}
}

export const searchJokesFailureAction = (error: string): SearchAction => {
    return { type: SEARCH_JOKES_FAILURE, error }
}

export const searchJokes = (q: string, page_number?: number): ThunkAction<Promise<void>, ReduxState, unknown, AnyAction> => {
    return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
        
        let page = page_number ? page_number : getState().search.page;

        // set loading state to true
        dispatch(searchJokesAction()) 

        try { // success
            let res = await axios.get(`https://api.laughfloor.com/jokes/search?q=${q}&page=${page}`);
            dispatch(searchJokesSuccessAction(res.data));
            //dispatch(loadAllJokesSuccessAction(res.data));
        } catch (error) { // fail
            dispatch(searchJokesFailureAction(error.response.data.message));
        }
         
    }
}