import React, { useState } from 'react';
import './search.scss';
import { IButton } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

export const Search: React.FC<{}> = (props) => {

    const query = new URLSearchParams(window.location.search);
    const q = query.get('q');
    const [searchTerm, setSearchTearm] = useState<string | undefined>(q || undefined);
    const history = useHistory(); 

    const updateSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTearm(e.currentTarget.value)
    }

    const redirect = () => {
        if(searchTerm !== undefined) {
            history.push({
                pathname: '/search',
                search: `?q=${searchTerm}`
            })
        }
    }

    const submitForm = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        redirect();
    }

    const submitFormButton = (e: React.MouseEvent<HTMLButtonElement>) => {
        redirect();
    }

    return <div className="search">
        <form onSubmit={submitForm}>
            <div className="search--row">
                <input aria-label="seach input" value={searchTerm} onChange={updateSearchTerm} className="search--input" type="text" autoComplete="off" placeholder="Search for jokes by type, category..." />
                <button aria-label="search" onClick={submitFormButton} className="search--button" type="button">
                    <FontAwesomeIcon icon={faSearch} size={'lg'} className="nav-icon" />
                </button>
            </div>
        </form>
    </div>
}
