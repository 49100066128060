import React from 'react';
import './page.scss';
import { Header } from '../components/header';
import { SearchJokes } from '../containers/joke/search';

export const SearchResultsPage: React.FC<{}> = (props) => {

    const query = new URLSearchParams(window.location.search);
    const q = query.get('q');
    const page = query.get('page');
    const p = (page ? parseInt(page) : 1);

    return (<>
      <Header />
      <div className="search-list-page">
        <div className="joke-list-page--list">
          <SearchJokes query={q || undefined} page={p} />
        </div>
      </div>
    </>)
}