import React, { useEffect } from 'react';
import './joke.scss';
import { IJoke } from '../../interfaces/joke';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';

const Actions: React.FC<{}> = (props) => {
  return (<div className="joke-card-actions">
    <FontAwesomeIcon icon={faShareSquare} size={'sm'} className="nav-icon" />
  </div>)
}

const Card: React.FC<IJoke & {className?: string}> = (props) => {
  return (<div className={`joke-card ${props.className}`}>
      <div className="joke-card--title"><h3>{props.joke.title.trim()}</h3></div>
      <div className="joke-card--body"><p>{props.joke.body.trim()}</p></div>
      {/*<Actions />*/}
    </div>)
}

export const HomeJokeCard: React.FC<IJoke & {className?: string}> = (props) => {
  return (<Card className="joke-card--home" {...props} />)
}

export const JokeCard: React.FC<IJoke & {className?: string}> = (props) => {
  return (<Card className="joke-card--overview" {...props} />)
}

export const JokeListCard: React.FC<IJoke> = (props) => {
    return (<a className="joke-card-link" href={`/jokes/${props.id}`}>
      <JokeCard className="joke-card--list" {...props} />
    </a>)
}