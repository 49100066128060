import React from 'react';
import './page.scss';
import logo from '../assets/images/laughfloor-logo.png';
import { useHistory, useParams } from 'react-router-dom';
import { Joke, JokeList } from '../containers/joke';
import { Header } from '../components/header';
import { Button } from '../components/input/button';
import ClockLoader from "react-spinners/ClipLoader";
import { ReduxState } from '../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { loadAllJokes } from '../actions/joke';
import { ListHeader } from '../components/list';

const Loader: React.FC<{}> = () => {
  return <ClockLoader
    size={14}
    color={"rgba(255,255,255,0.8)"}
    loading={true}
/>
}

export const JokePage: React.FC<{}> = (props) => {
    const { id } = useParams<{id:string}>();

    return (<>
      <Header />
      <div className="joke-page">
        <div className="joke-page--list">
          <ListHeader />
          <Joke id={id} />  
        </div>
      </div>
    </>)
}

export const JokeListPage: React.FC<{}> = (props) => {

  const { id } = useParams<{id:string}>();
  const query = new URLSearchParams(window.location.search);
  const page = query.get('page');
  const joke = useSelector((state: ReduxState) => state.jokeState);
  const next_page = (page ? parseInt(page) : 1) + 1;
  const dispatch = useDispatch();
  const history = useHistory();

  const loadMore = () => {
    history.push({
      pathname: '/jokes',
      search: `?page=${next_page}`
    })
    dispatch(loadAllJokes(next_page))
  }

  return (<>
    <Header />
    <div className="joke-list-page">
      <div className="joke-list-page--list">
        <JokeList page={page ? parseInt(page) : undefined} />
        <div className="joke-list-page--next-button">
          <Button onClick={loadMore} color="blue">
            {joke.loading ? <Loader /> : 'More'}
            <a type="hidden" href={`https://laughfloor.com/jokes?page=${next_page}`}></a>
          </Button>
        </div>
      </div>
    </div>
  </>)
}