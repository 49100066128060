import React, { useEffect } from 'react';
import './page.scss';
import logo from '../assets/images/laughfloor-logo.png';
import { RandomJoke } from '../containers/joke';
import { Button } from '../components/input/button';
import { useDispatch, useSelector } from 'react-redux';
import { loadRandomJoke } from '../actions/joke';
import ClockLoader from "react-spinners/ClipLoader";
import { ReduxState } from '../reducers';
import { NavLink } from 'react-router-dom';
import { Search } from '../components/input/search';

const Loader: React.FC<{}> = () => {
    return <ClockLoader
      size={14}
      color={"rgba(255,255,255,0.8)"}
      loading={true}
  />
}

export const HomePage: React.FC<{}> = (props) => {

    const dispatch = useDispatch();
    const joke = useSelector((state: ReduxState) => state.jokeState);

    const loadNext = () => {
      dispatch(loadRandomJoke())
    }

    return (<div className="home-page">
      <div className="home-page--logo"><img src={logo} alt="logo" /></div>
      <div className="home-page--search"><Search /></div>
      <div className="home-page--joke-area">
        <RandomJoke />
        <div className="home-page--next-joke">
          <Button onClick={loadNext} color="blue">
            {joke.loading ? <Loader /> : 'Another'}
          </Button>
        </div>
      </div>
      <footer className="home-page--footer">
        <NavLink className="home-page--link" to="/jokes">Browse Jokes</NavLink>
      </footer>
    </div>)
}