import ReactDOM from 'react-dom';
import * as React from 'react';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers';
import { createStore, applyMiddleware } from 'redux';
import { HomePage, JokePage, JokeListPage, SearchResultsPage } from './pages';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import './base.scss';

const routing = (
    <Provider store={createStore(rootReducer, applyMiddleware(thunk))}>
        <Router>
            <Switch>

                {/* SEARCH RESULTS */}
                <Route path="/search" component={SearchResultsPage} />

                {/* JOKE PAGE */}
                <Route path="/jokes/:id" component={JokePage} />
                <Route path="/jokes" component={JokeListPage} />

                {/* ERROR 404 */}
                <Route path="*" component={HomePage} />

            </Switch>
        </Router>
    </Provider>
)
  
ReactDOM.render(routing, document.getElementById('root'));
  