import React, { useEffect } from 'react';
import { JokeListCard } from '../../components/joke';
import { loadAllJokes } from '../../actions/joke';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import ClockLoader from "react-spinners/ClipLoader";

const Loader: React.FC<{}> = () => {
  return <div className="joke-card-loader">
    <ClockLoader
        size={20}
        color={"rgba(255,255,255,0.8)"}
        loading={true}
    />
  </div>
}

export const JokeList: React.FC<{page?: number}> = (props) => {

    const dispatch = useDispatch();
    const state = useSelector((state: ReduxState) => state.jokeState);

    useEffect(() => {
      dispatch(loadAllJokes(props.page))
    }, [dispatch]);

    return (<>
      {state.jokes.map((joke, key) => <JokeListCard {...joke} key={key} />)}
    </>)

}