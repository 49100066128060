import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import axios from 'axios';
import { ReduxState } from '../reducers';

import { 
    LOAD_JOKE,
    LOAD_JOKE_SUCCESS,
    LOAD_JOKE_FAILURE,
    LOAD_ALL_JOKES,
    LOAD_ALL_JOKES_FAILURE,
    LOAD_ALL_JOKES_SUCCESS,
    JokeAction,
    IJoke,
} from '../interfaces/joke';

// LOAD RANDOM JOKE

export const loadRandomJokeAction = (): JokeAction => {
    return { type: LOAD_JOKE }
}

export const loadRandomJokeSuccessAction = (joke: IJoke): JokeAction => {
    return { type: LOAD_JOKE_SUCCESS,  joke}
}

export const loadRandomJokeFailureAction = (error: string): JokeAction => {
    return { type: LOAD_JOKE_FAILURE, error }
}

export const loadRandomJoke = (): ThunkAction<Promise<void>, ReduxState, unknown, AnyAction> => {
    return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
        
        // set loading state to true
        dispatch(loadRandomJokeAction()) 

        try { // success
            let res = await axios.get('https://api.laughfloor.com/jokes?random=true');
            dispatch(loadRandomJokeSuccessAction(res.data))
        } catch (error) { // fail
            dispatch(loadRandomJokeFailureAction(error.response.data.message));
        }
    }
}

// LOAD JOKE 


export const loadJokeAction = (): JokeAction => {
    return { type: LOAD_JOKE }
}

export const loadJokeSuccessAction = (joke: IJoke): JokeAction => {
    return { type: LOAD_JOKE_SUCCESS,  joke}
}

export const loadJokeFailureAction = (error: string): JokeAction => {
    return { type: LOAD_JOKE_FAILURE, error }
}

export const loadJoke = (joke_id: string): ThunkAction<Promise<void>, ReduxState, unknown, AnyAction> => {
    return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
        
        const state = getState();
        const exists = state.jokeState.jokes.find(x => x.id === joke_id);

        if(exists) {
            dispatch(loadJokeSuccessAction(exists));
        } else {
            // set loading state to true
            dispatch(loadRandomJokeAction()) 

            try { // success
                let res = await axios.get(`https://api.laughfloor.com/jokes/${joke_id}`);
                dispatch(loadRandomJokeSuccessAction(res.data))
            } catch (error) { // fail
                dispatch(loadRandomJokeFailureAction(error.response.data.message));
            }
        }
         
    }
}


// LOAD ALL JOKES


export const loadAllJokesAction = (): JokeAction => {
    return { type: LOAD_ALL_JOKES }
}

export const loadAllJokesSuccessAction = (jokes: Array<IJoke>): JokeAction => {
    return { type: LOAD_ALL_JOKES_SUCCESS, jokes}
}

export const loadAllJokesFailureAction = (error: string): JokeAction => {
    return { type: LOAD_ALL_JOKES_FAILURE, error }
}

export const loadAllJokes = (page_number?: number): ThunkAction<Promise<void>, ReduxState, unknown, AnyAction> => {
    return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => ReduxState): Promise<void> => {
        
        let page = page_number ? page_number : getState().jokeState.page;

        // set loading state to true
        dispatch(loadAllJokesAction()) 

        try { // success
            let res = await axios.get(`https://api.laughfloor.com/jokes?page=${page}`);
            dispatch(loadAllJokesSuccessAction(res.data))
        } catch (error) { // fail
            dispatch(loadAllJokesFailureAction(error.response.data.message));
        }
         
    }
}