import React, { useEffect } from 'react';
import { HomeJokeCard } from '../../components/joke';
import { loadRandomJoke } from '../../actions/joke';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import ClockLoader from "react-spinners/ClipLoader";
import './joke.scss';

const Loader: React.FC<{}> = () => {
    return <div className="joke-card-loader">
      <ClockLoader
          size={20}
          color={"rgba(255,255,255,0.8)"}
          loading={true}
      />
    </div>
}

export const RandomJoke: React.FC<{}> = (props) => {

    const dispatch = useDispatch();
    const joke = useSelector((state: ReduxState) => state.jokeState);
    
    useEffect(() => {
      dispatch(loadRandomJoke())
    }, [dispatch]);

    // if no joke, show loader
    if(!joke.joke) {
      return <Loader />
    }

    return (<>
      <HomeJokeCard {...joke.joke} />
    </>)
}