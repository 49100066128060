import React from 'react';
import './button.scss';
import { IButton } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

export const Button: React.FC<IButton> = (props) => {

    const color = () => {
        if(props.color === 'blue') return 'main-button--blue';
        return '';
    }

    return (<button disabled={props.disabled} className={`main-button ${color()} ${props.className ? props.className : ''}`} onClick={props.onClick} aria-label={props.value}>{props.value || props.children}</button>)
}

export const BackButton: React.FC<IButton> = (props) => {

    const color = () => {
        if(props.color === 'blue') return 'main-button--blue';
        return '';
    }

    return (<button disabled={props.disabled} className={`back-button ${color()} ${props.className ? props.className : ''}`} onClick={props.onClick} aria-label={props.value}>
        <FontAwesomeIcon icon={faArrowLeft} size={'1x'} className="nav-icon" />
    </button>)
}