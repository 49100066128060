import type { ICategory } from './category';

export interface IJoke {
    id: string,
    joke: {
        title: string,
        body: string,
        full: string,
        rating: number,
    },
    admin : {
        approved: boolean,
        checked: boolean
    },
    categories: Array<ICategory>
}

export const RESET_SEARCH_STATE     = "RESET_SEARCH_STATE";
export const SEARCH_JOKES           = 'SEARCH_JOKES_JOKE';
export const SEARCH_JOKES_SUCCESS   = 'SEARCH_JOKES_SUCCESS';
export const SEARCH_JOKES_FAILURE   = 'SEARCH_JOKES_FAILURE';
export const LOAD_JOKE              = 'LOAD_CURRENT_JOKE';
export const LOAD_JOKE_SUCCESS      = 'LOAD_CURRENT_JOKE_SUCCESS';
export const LOAD_JOKE_FAILURE      = 'LOAD_CURRENT_JOKE_FAILURE';
export const LOAD_ALL_JOKES         = 'LOAD_ALL_JOKES';
export const LOAD_ALL_JOKES_SUCCESS = 'LOAD_ALL_JOKES_SUCCESS';
export const LOAD_ALL_JOKES_FAILURE = 'LOAD_ALL_JOKES_FAILURE';

export interface IJokeState {
    joke?: IJoke,
    loading: boolean,
    page: number,
    jokes: Array<IJoke>,
    error?:string
}

export interface ISearchState {
    loading: boolean,
    page: number,
    results: Array<IJoke>,
    error?:string
}

interface loadJokeAction {
    type: typeof LOAD_JOKE
}

interface loadJokeSuccessAction {
    type: typeof LOAD_JOKE_SUCCESS,
    joke: IJoke
}

interface loadJokeFailureAction {
    type: typeof LOAD_JOKE_FAILURE,
    error: string
}

interface loadAllJokesAction {
    type: typeof LOAD_ALL_JOKES
}

interface loadAllJokesSuccessAction {
    type: typeof LOAD_ALL_JOKES_SUCCESS,
    jokes: Array<IJoke>,
    loading?: boolean,
    page?: number
}

interface loadAllJokesFailureAction {
    type: typeof LOAD_ALL_JOKES_FAILURE,
    error: string
}

interface searchJokesAction {
    type: typeof SEARCH_JOKES
}

interface searchJokesSuccessAction {
    type: typeof SEARCH_JOKES_SUCCESS,
    results: Array<IJoke>,
    loading?: boolean,
    page?: number
}

interface searchJokesFailureAction {
    type: typeof SEARCH_JOKES_FAILURE,
    error: string
}

interface resetSearchJokesSuccessAction {
    type: typeof RESET_SEARCH_STATE,
    results: Array<IJoke>,
    loading?: boolean,
    page?: number
}

export type SearchAction = (
    searchJokesAction | 
    searchJokesFailureAction |
    searchJokesSuccessAction | 
    resetSearchJokesSuccessAction |
    resetSearchJokesSuccessAction
)

export type JokeAction = (
    loadJokeAction |
    loadJokeSuccessAction | 
    loadJokeFailureAction |
    loadAllJokesAction |
    loadAllJokesSuccessAction |
    loadAllJokesFailureAction
)